/* src/index.css */

/* Apply a global font style */
body {
    font-family: 'Arial', sans-serif;
  }
  
  /* Reset margin and padding for consistency */
  body, h1, h2, h3, p, ul, li {
    margin: 0;
    padding: 0;
  }
  
  /* Set a background color for the entire app */
  body {
    background-color: #f8f8f8;
  }
  
  /* Add some spacing to sections */
  section {
    margin-bottom: 20px;
  }
  
  /* Style links */
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  