body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

header {
  background-color: transparent;
  color: white;
  text-align: center;
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

main {
  display: flex;
  flex-wrap: wrap;
}

main > section {
  width: 50%;
}

@media screen and (max-width: 10000px) {
  main > section {
    width: 1000%;
  }
}

.footer-container {
  background: rgb(255, 255, 255);
  color: rgb(55, 74, 224);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container img {
  max-width: 100%;
  width: 150px;
}

.contact-info {
  text-align: center;
}

.contact-info p {
  margin: 5px 0;
}

.social-media {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.social-media a {
  margin-right: -150px;
}

.social-media .facebook-icon {
  width: 25%;
}

.social-media .instagram-icon {
  width: 25%;
}

.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-image: url('images.jpg');
  background-size: cover;
  background-position: center;
  text-align: center;
}

.centered-title {
  font-size: 4rem;
  color: white;
  margin: 0;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.transparent-button {
  background-color: transparent;
  border: 2px solid #ffffff;
  color: #ffffff;
  padding: 15px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 10px;
}

.transparent-button:hover {
  background-color: #ffffff;
  color: #2d2a72;
}

@media screen and (max-width: 768px) {
  .centered-title {
    font-size: 2.5rem;
  }

  /* Removed stacking rule to keep buttons side by side */
}



.transparent-button {
  background-color: transparent;
  border: 2px solid #ffffff;
  color: #ffffff;
  padding: 15px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 10px;
}

.transparent-button:hover {
  background-color: #ffffff;
  color: #2d2a72;
}

.taskbar {
  background-color: rgba(25, 33, 74, 0.9);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  font-size: 18px;
  transition: top 0.3s;
  z-index: 1000;
}

.taskbar .menu-toggle {
  display: none;
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
}

.options {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 50px;
  margin: 0;
}

.options a {
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-size: 18px;
  white-space: nowrap;
}

.options li {
  font-weight: bold;
}

.options a:hover {
  color: rgb(55, 74, 224);
}

@media screen and (max-width: 768px) {
  .taskbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
  }

  .taskbar .menu-toggle {
    display: block;
  }

  .options {
    flex-direction: column;
    gap: 15px;
    display: none;
    width: 100%;
  }

  .options.show {
    display: flex;
  }

  .options li {
    font-size: 18px;
  }
}

section {
  padding: 60px;
  background-color: #ffffff;
  text-align: center;
}

section h2 {
  color: rgb(46, 71, 180);
  font-size: 3em;
}

section p {
  color: rgb(46, 71, 180);
  margin-top: 20px;
  font-size: 1.75em;
}

.about-us-panel {
  background: -webkit-linear-gradient(to right, #2657eb, #de6161);
  background: linear-gradient(to right, #2657eb, #de6161);
  padding: 5%;
  align-items: center;
}

.about-us-content {
  max-width: 800px;
  margin: 0 auto;
}

.about-us-panel h3 {
  color: rgb(247, 213, 96);
  font-size: 40px;
}

.stars-container {
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  display: flex;
}

.star {
  text-align: center;
  margin: 2vw;
  color: rgb(247, 213, 96);
}

.star img {
  width: 50%;
  max-width: 100%;
  object-fit: contain;
}

.star a {
  display: inline-block;
  color: rgb(247, 213, 96);
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 2vw;
  object-fit: contain;
  overflow: hidden;
  white-space: nowrap;
}

.star a:hover {
  color: #fcfcfc;
}

.stars-container li {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 10px;
    width: 100%;
  }

  .chat-container {
    margin: 0;
    border-radius: 0;
    height: 100vh;
  }

  .message-input {
    width: 100%;
    padding: 10px;
  }

  .message {
    max-width: 90%;
  }
}

@viewport {
  width: device-width;
  zoom: 1.0;
}


