/* Style2.css */
.personal-taxes-section {
    width: 100vw;
    height: 90vh; 
  
      background-image: url('./back.jpg'); /* Adjust the path based on your project structure */
      background-size: cover;
      background-position: center;
      
      display: flex;
      flex-direction: column; /* Stack child elements vertically */
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      
    
  }
  
  .personal-taxes-section h2 {
    
    color: rgb(55, 74, 224);
    font-size: 100px;
    margin-top: 1px;
     
   
  }
  
 
  
  .transparent-button {
    background-color: rgba(55, 73, 209, 0.591);
    border: 1px solid rgb(255, 255, 255); /* Add border for better visibility */
    color: rgb(255, 255, 255);
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 50px 0;
  }
  
  .transparent-button:hover {
    background-color: rgb(55, 74, 224); /* Semi-transparent white on hover */
  }
  .taxp {
    padding: 20px;
    background-color: #de6161;
    
    
  }
  .taxp p{
    color: rgb(255, 255, 255);
  }
  .contact-section{
  padding: 20px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  }
  .contact-section p{

    color: rgb(55, 74, 224);
  }
  .trustus{
  padding: 40px;
  background: -webkit-linear-gradient(to right, #2657eb, #de6161);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2657eb, #de6161); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  text-align: center;
  }
  .trustus h2{
    color: #ffffff;
  }
  .trustus p{
    color: rgb(255, 255, 255);
  }
  .business-tax {
    width: 100vw;
    height: 90vh; 
  
      background-image: url('./back.jpg'); /* Adjust the path based on your project structure */
      background-size: cover;
      background-position: center;
      
      display: flex;
      flex-direction: column; /* Stack child elements vertically */
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      
    }
    .bustax{
      padding: 20px;
      background-color: #de6161;
      
    }
    .bustax p {
      color: rgb(255, 255, 255);
    }
    .business-tax h2 {
      color: rgb(55, 74, 224);
      font-size: 100px;
      margin-top: 50px;
    }
    .trustusbiz{
        padding: 40px;
        background: linear-gradient(to right, #2657eb, #de6161); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
       
        text-align: center;

    }
    .trustusbiz h2{
      color: #ffffff;
    }
    .trustusbiz p{
        color: rgb(255, 255, 255)
    }
    .incorporations {
        width: 100vw;
        height: 90vh; 
      
          background-image: url('./back.jpg'); /* Adjust the path based on your project structure */
          background-size: cover;
          background-position: center;
          
          display: flex;
          flex-direction: column; /* Stack child elements vertically */
          align-items: center;
          justify-content: center;
          background-color: #ffffff;
          
        }
        .incorporations h2 {
          color: rgb(55, 74, 224);
          font-size: 100px;
          
          margin-top: 70px;
        }
        .incorp{
          padding: 20px;
          background-color: #de6161;
        }
        .incorp p{
          
          color: #ffffff;
        }
        .trustusincorp{
            padding: 40px;
            text-align: center;
    
        }
       
        .notary {
            width: 100vw;
            height: 90vh; 
          
              background-image: url('./back.jpg'); /* Adjust the path based on your project structure */
              background-size: cover;
              background-position: center;
              
              display: flex;
              flex-direction: column; /* Stack child elements vertically */
              align-items: center;
              justify-content: center;
              background-color: #ffffff;
              
            }
            .notary h2 {
              color: rgb(55, 74, 224);
              font-size: 100px;
              margin-top: 50px;
            }
            .note{
              background-color: #de6161;
            
              padding: 20px;
            }
            .note p {
              color: #ffffff;
            }
            .notarybiz{
                padding: 40px;
                background: linear-gradient(to right, #2657eb, #de6161); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
       
                text-align: center;
        
            }
            .notarybiz p{
                color: rgb(255, 255, 255)
            }
            .notarybiz h2{
              color: #ffffff;

            }
            /* ContactForm.css */

            .FormWrapper {
                display: flex;
                align-items: center;
              }
              
  
              .Contact {
                width: 100vw;
                height: 100vh;
                background-image: url('images.jpg'); /* Adjust the path based on your project structure */
                background-size: cover;
                background-position: center;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white; /* Add styles for text color, etc. */
              }
              
              .contact-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            
            .ContactForm {
                flex: 1;
                width: 70%;
                max-width: 500px;
                margin-right: 40px; /* Add a 10px margin on the right side */
                border: 20px solid rgb(0, 0, 0);
                border-radius: 8px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }
            
            .MapContainer {
              
              height: 300px;
                flex: 1;
                width: 70%;
                max-width: 500px;
                margin-left: 10px; /* Add a 10px margin on the left side */
                border: 40px solid rgb(0, 0, 0);
                border-radius: 8px;
               
                
            }
              
              
              label {
                display: block;
                margin-bottom: 8px;
              }
              
              input,
              textarea {
                width: 100%;
                padding: 8px;
                margin-bottom: 16px;
                box-sizing: border-box;
                border: 1px solid #ccc;
                border-radius: 4px;
              }
              
              button {
                background-color: #3e429b;
                color: white;
                padding: 10px 15px;
                border: none;
                border-radius: 4px;
                cursor: pointer;
              }
              
              button:hover {
                background-color: #000000;
              }
              
              .success-message,
              .error-message {
                margin-top: 10px;
                padding: 10px;
                border-radius: 4px;
              }
              
              .success-message {
                background-color: #dff0d8;
                color: #3c763d;
              }
              
              .error-message {
                background-color: #f2dede;
                color: #a94442;
              }
              .copyright-container {
                background-color: rgb(55, 74, 224); /* Dark gray background color */
                color: #fff; /* White text color */
             
                padding: 10px;
              }
              
              .copyright-container p {
                margin: 0; /* Remove default margin for the paragraph */
              }
              